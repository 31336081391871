<template>
  <div>
    <span class="viewH">Отзывы</span>
    <hr>
    <div class="wrapper">
      <v-form ref="reviewsSelect" v-if="!visible">
        <div class="review-select">
          <v-text-field
            label="Выберите дату"
            placeholder="Placeholder"
            outlined
            type="date"
            class="rdate"
            dense
            v-model="selectDate"
            :rules="selectDateRules"
            required
        ></v-text-field>
        <v-btn
          color="#4682B4"
          @click="selectReviews()"
        >выбрать</v-btn>
        </div>
        <hr>
      </v-form>
      <div class="review-table">
        <div
         style="
          text-align: center;
          margin-top: 25px;
          font-weight: bold;
          color: greenyellow;
         "
        >
          <span v-if="getReviews.reviews && getReviews.reviews.length == 0">Ни чего не найдено :(</span>
        </div>
        <div class="table-wrapper" v-if="!visible">
          <table
            v-for="(item, i) in getReviews.reviews" 
            :key="'admReviewT' + i"
            @click="response(getReviews.reviews[i])"
          >
            <thead>
              <tr>
                <th>дата</th>
                <th>пользователь</th>
                <th>отзыв</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style="font-weight: bold;" nowrap>{{item.date | date('smalldt')}}</td>
                <td style="text-align: center; font-weight: bold;">{{item.username}}</td>
                <td>{{item.review}}</td>
              </tr>
              <tr v-if="item.res_response != null">
                <td 
                  colspan="3" 
                  style="
                    text-align: center; 
                    font-weight: bold;
                    background-color: #CD853F;
                  "
                >ответ</td>
              </tr>
              <tr v-if="item.res_response != null">
                <td>{{item.res_date | date('smalldt')}}</td>
                <td colspan="2" v-html="item.res_response.replace(/\n/g, '<br/>')"></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="response-wrapper" v-if="visible">
          <div class="user">
            <div>Пользователь: <span>{{review.username}}</span></div>
            <div>Дата: <span>{{review.date | date('smalldt')}}</span></div>
          </div>
          <div style="margin-bottom: 7px">Отзыв:</div>
          <v-textarea
            v-model="review.review"
            outlined
            name="input-7-4"
            color="#4caf50"
            disabled
          ></v-textarea>
          <div style="margin-bottom: 7px">Ответ:</div>
          <v-textarea
            v-model="review.res_response"
            outlined
            name="input-7-4"
            color="#4caf50"
            :prefix="`${review.username},`"
            autofocus
          ></v-textarea>
          <div class="response-btn">
            <v-btn small color="#f44336" @click="cancel()">Отмена</v-btn>
            <v-btn 
              v-if="review.res_response == null" 
              small 
              color="#4caf50"
              @click="insert()"
            >Сохранить</v-btn>
            <v-btn
              v-if="review.res_response != null"
              small 
              color="#4caf50"
              @click="update()"
            >Изменить</v-btn>
            <v-btn
              v-if="review.res_response != null"
              small 
              color="#FF4500"
              @click="resDelete()"
            >Удалить ответ</v-btn>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
export default {
  props: {
    messageOn: Function,
    loadingWindow: Function
  },
  data: () => ({
    visible: false,
    review: {},
    selectDate: null,
    selectDateRules: [
      v => !!v || 'Обязательное поле'
    ],
  }),
  methods: {
    ...mapActions(['reviews', 'responseInsert', 'responseUpdate', 'responseDelete']),
    date: () => {
      const date = new Date()
      let dd = date.getDate();
      if (dd < 10) dd = '0' + dd;
      let mm = date.getMonth() + 1;
      if (mm < 10) mm = '0' + mm;
      let yy = date.getFullYear();
      return `${yy}-${mm}-${dd}`;
    },
    time: () => {
      const date = new Date()
      const options = {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
      }
      const formater = new Intl.DateTimeFormat('ru-RU', options)
      return formater.format(date)
    },
    response(review) {
      this.review.id = review.id
      this.review.review  = review.review
      this.review.username = review.username
      this.review.date = review.date
      this.review.res_id = review.res_id
      this.review.res_response = review.res_response
      this.visible = true
    },
    selectReviews() {
      if (this.$refs.reviewsSelect.validate()) {
        this.reviews({selectDate: this.selectDate})
      }
    },
    async insert() {
      this.loadingWindow(true)
      let res = await this.responseInsert({
        id: this.review.id,
        response: this.review.res_response,
        date: `${this.date()} ${this.time()}`,
        selectDate: this.selectDate
      })
      this.loadingWindow(false)
      if(res.rev === 'ok') {
        this.messageOn('Ответ на отзыв добавлен','#4caf50')
        this.visible = false
      } else {
        this.messageOn('[ошибка]: ответ не добавлен','#ff4500')
      }
    },
    async update() {
      this.loadingWindow(true)
      let res = await this.responseUpdate({
        response: this.review.res_response,
        id: this.review.res_id,
        selectDate: this.selectDate
      })
      this.loadingWindow(false)
      if(res.rev === 'ok') {
        this.messageOn('Ответ на отзыв обновлен','#4caf50')
        this.visible = false
      } else {
        this.messageOn('[ошибка]: ответ не обновлен','#ff4500')
      }
    },
    async resDelete() {
      this.loadingWindow(true)
      let res = await this.responseDelete({
        id: this.review.res_id,
        selectDate: this.selectDate
      })
      this.loadingWindow(false)
      if(res.rev === 'ok') {
        this.messageOn('Ответ на отзыв удален','#4caf50')
        this.visible = false
      } else {
        this.messageOn('[ошибка]: ответ не удален','#ff4500')
      }
    },
    cancel() {
      this.review = {}
      this.visible = false
    }
  },
  computed: {
    ...mapGetters(['getReviews'])
  },
  mounted() {
    this.$store.commit('mutReviews', [])
  }
}
</script>

<style lang="scss" scoped>
  .wrapper {
    margin: 25px 0 0 0;
    .review-select {
      display: flex;
      align-items: baseline;
      button {
        margin-left: 10px;
      }
      .rdate {
        max-width: 250px;
      }
    }
    .review-table {
      table {
        margin: 25px 0;
        border-collapse: collapse;
        width: 100%;
        color: #000000;
        cursor: pointer;
        th, td {
          border: 1px solid #696969;
          /* border-radius: 3px; */
          padding: 3px 10px;
        }
        thead {
          th {
            background-color: #4caf50;
          }
        }
        tbody {
          background-color: #dfdfdf;
        }
      }
      .response-wrapper {
        div {
          margin-bottom: 5px;
          color: rgb(170, 170, 170);
          &:nth-child(2) {
            margin-bottom: 25px;
          }
          span {
            color: greenyellow;
          }
        }
        .response-btn {
          button {
            margin-right: 7px;
          }
        }
      }
    }
  }
</style>
